var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-x-box"},[_c('div',{staticClass:"mind-class-content clearfix",style:('width:' + _vm.scrollXWidth + 'px;' + 'left:' + _vm.left + 'px')},_vm._l((_vm.data),function(each,index){return _c('div',{key:index,staticClass:"modal-box-children",on:{"click":function($event){return _vm.clickCreated(each, index)}}},[_c('div',{staticClass:"gray-box"}),(
          !(
            each.layoutType == 4 &&
            each.mindType == 1 &&
            each.isEmpty == true
          )
        )?_c('div',{style:('width: 160px;height: 140px;background:url(' +
          each.icon +
          ') no-repeat;background-size: 160px 140px;background-position:center;overflow:hidden;')}):_c('div',{style:('width: 160px;height: 140px;background:url(' +
          each.icon +
          ') no-repeat;background-size: cover;background-position:center;overflow:hidden;')},[_c('div',{staticClass:"empty-text"},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.My_Mind_Blank_Mind_Map))+" ")]),_c('div',{staticClass:"item-explain"},[_vm._v(" 请问请问请问 ")])])])}),0),(_vm.showBoxWidth < _vm.scrollXWidth)?_c('div',{staticClass:"left-scroll-button",on:{"click":_vm.scrollRight}},[_c('a-icon',{attrs:{"type":"left"}})],1):_vm._e(),(_vm.showBoxWidth < _vm.scrollXWidth)?_c('div',{staticClass:"right-scroll-button",on:{"click":_vm.scrollLeft}},[_c('a-icon',{attrs:{"type":"right"}})],1):_vm._e(),_c('a-modal',{staticClass:"report-member-model",attrs:{"centered":true,"title":_vm.getString(_vm.strings.Mind_Edit_Join_Membership),"cancelText":_vm.getString(_vm.strings.Global_Cancel),"okText":_vm.getString(_vm.strings.Mind_Edit_Upgrade)},on:{"cancel":_vm.storageIsMeberCancelHandleOk,"ok":_vm.storageIsMeberDeleteHandleOk},model:{value:(_vm.storageIsMeberModelAccout),callback:function ($$v) {_vm.storageIsMeberModelAccout=$$v},expression:"storageIsMeberModelAccout"}},[_c('div',{staticClass:"report-crisps-style-box"},[_c('div',{staticClass:"report-crisps-style",staticStyle:{"font-size":"18px","font-family":"Noto Sans SC","text-align":"center","display":"block"}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Insufficient_Storage_Space))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }